
import { OnlineCourses } from "@/api";
import { OnlineCourse } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import EssayTab from "@/components/onlineCourses/EssayTab.vue";
import GeneralInfo from "@/components/onlineCourses/GeneralInfo.vue";
import LearningMaterials from "@/components/onlineCourses/LearningMaterials.vue";
import PracticalTest from "@/components/onlineCourses/PracticalTab.vue";
import SummativeTest from "@/components/onlineCourses/SummativeTab.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    PageTitle,
    GeneralInfo,
    LearningMaterials,
    PracticalTest,
    SummativeTest,
    EssayTab
  }
})
export default class EditOnlineCoursePage extends Vue {
  tab = 0;
  processing = false;

  course: OnlineCourse = {} as OnlineCourse;
  oldCourse: OnlineCourse = {} as OnlineCourse;

  hasFormativeTest = false;
  hasSummativeTest = false;
  hasPracticalTest = false;
  hasEssayTest = false;

  get buttonsArray() {
    const buttonsArray = [
      {
        text: "Back",
        icon: "mdi-chevron-left",
        color: "white",
        action: "back",
        disabled: false
      }
    ];
    if (!this.isCourseBlocked) {
      buttonsArray.push({
        text: "Save",
        icon: "mdi-pencil-box-outline",
        color: "dark",
        action: "save",
        disabled: false
      });
    }
    return buttonsArray;
  }

  get courseId() {
    return this.$route.params.id;
  }
  get formativeMaxGrade() {
    return this.course.formativeMaxGrade;
  }
  get summativeMaxGrade() {
    return this.course.summativeMaxGrade;
  }
  get isUSQualificationType() {
    return this.course?.qualificationType === "US" || false;
  }
  get isQualification() {
    return this.course?.qualificationType === "Qualification";
  }
  get essayTabName() {
    return this.isQualification
      ? "Essay, Case Studys & Reflexive Questions"
      : "Essay Assessment";
  }
  get isCourseBlocked() {
    return this.course.blocked;
  }

  get headersTitle() {
    return [
      {
        icon: "mdi-cast-education",
        header: `${this.course.name || "Edit online course"}  ${this
          .isCourseBlocked && "(View Only)"}`
      }
    ];
  }

  created() {
    this.loadCourse();
  }

  @Watch("courseId")
  updCourse() {
    this.loadCourse();
  }

  async loadCourse() {
    this.processing = true;

    try {
      this.course = await OnlineCourses.info(this.courseId);
      this.oldCourse = JSON.parse(JSON.stringify(this.course));

      this.hasFormativeTest = !!this.course.formativeMaxGrade || false;
      this.hasSummativeTest = !!this.course.summativeMaxGrade || false;
      this.hasEssayTest = this.course.essayExists || false;
      this.hasPracticalTest = this.course.practicalExists || false;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    } finally {
      this.processing = false;
    }
  }

  onSave() {
    const btn = this.buttonsArray[1];
    btn.disabled = true;
    if (this.tab === 0) {
      (this.$refs.generalInfo as Vue & {
        onSave: () => Promise<any>;
      })
        .onSave()
        .finally(() => {
          btn.disabled = false;
        });
    } else if (this.tab === 1) {
      (this.$refs.learningMaterial as Vue & {
        save: () => Promise<any>;
      })
        .save()
        .finally(() => {
          btn.disabled = false;
        });
    } else if (this.tab === 2) {
      (this.$refs.summative as Vue & {
        save: () => Promise<any>;
      })
        .save()
        .finally(() => {
          btn.disabled = false;
        });
    } else if (this.tab === 3) {
      (this.$refs.practical as Vue & {
        save: () => Promise<any>;
      })
        .save()
        .finally(() => {
          btn.disabled = false;
        });
    } else if (this.tab === 4) {
      (this.$refs.essay as Vue & {
        save: () => Promise<any>;
      })
        .save()
        .finally(() => {
          btn.disabled = false;
        });
    }
  }
  async onFormativeSaved() {
    if (this.isUSQualificationType) this.tab += 1;
    this.loadCourse();
  }
  async onSummativeSaved() {
    this.loadCourse();
  }
  async onGoBack() {
    await this.$router.push({ name: "OnlineCoursesList" });
  }
  onGeneralInfoSaved() {
    if (this.isUSQualificationType) {
      this.tab += 1;
    }
  }
  onDeleteEssay() {
    this.hasEssayTest = false;
    this.tab = 0;
  }
  onDeletePracticalTest() {
    this.hasPracticalTest = false;
    this.tab = 0;
  }
  onDeleteFormativeTest() {
    this.hasFormativeTest = false;
    this.tab = 0;
  }
  onDeleteSummativeTest() {
    this.hasSummativeTest = false;
    this.tab = 0;
  }
  addFormativeTest() {
    if (this.isCourseBlocked) return;
    this.hasFormativeTest = true;
  }
  addSummativeTest() {
    if (this.isCourseBlocked) return;
    this.hasSummativeTest = true;
  }
  addPracticalTest() {
    if (this.isCourseBlocked) return;
    this.hasPracticalTest = true;
  }
  addEssayTest() {
    if (this.isCourseBlocked) return;
    this.hasEssayTest = true;
  }
}
